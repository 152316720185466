import React, { FC } from 'react';
import { graphql } from 'gatsby';
import withDefaultTransition from '../hocs/withDefaultTransition';
import { NewsArticleArticleBlogQuery, NewsArticleBlogFragment } from '../entities/operationResults';
import NewsArticle from './organisms/NewsArticle';

export const query = graphql`
    query NewsArticleArticleBlogQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...NewsArticleBlogFragment
            }
        }
    }
`;

// TODO: Break images in fragments. Causes the data to not be found if I do it in fragments
export const fragment = graphql`
    fragment NewsArticleBlogFragment on Craft_NewsArticleArticleBlog {
        id
        type {
            name
        }
        uri
        title
        postDate
        teamMemberEntry {
            ... on Craft_TeamMember {
                firstName
                lastName
                teamMemberImage {
                    ...ArticleAuthor
                }
            }
        }
        coverImage {
            title
            url
            url500: url(fit: { width: 500, quality: 100 })
            url1000: url(fit: { width: 1000, quality: 100 })
            url1500: url(fit: { width: 1500, quality: 100 })
            url2000: url(fit: { width: 2000, quality: 100 })
            width
            height
        }
        intro {
            subText {
                content
            }
            text {
                content
            }
        }
        richContent {
            ...RichContentFragment
        }
        nextArticle {
            uri
            title
        }
        directContact {
            ...DirectContactFragment
        }
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    data: NewsArticleArticleBlogQuery;
}

const NewsArticleBlog: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as NewsArticleBlogFragment;
    return <NewsArticle entry={entry} />;
};

export default withDefaultTransition(NewsArticleBlog);
